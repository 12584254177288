.featuredProducts {
  max-width: calc((260px + 50px) * 4 - 50px);
  padding: 100px 10px 0;
  margin: auto;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    h1 {
      flex: 2;
      text-transform: capitalize;
    }

    p {
      flex: 3;
      color: gray;
    }
  }

  .bottom {
    // display: flex;
    // justify-content: center;
    // gap: 42.8px;
    // flex-wrap: wrap;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 20px; /* Adjust the gap between items as needed */
  }
}
