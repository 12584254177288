.link {
  // min-width: 260px;
  // flex: 1 0 22%;
  // max-width: calc(22% - 20px);
  // @media screen and (max-width: 1024px) {
  //   .child-element {
  //     max-width: none; /* Remove the maximum width for desktop view */
  //   }
  // }
  // width: min(260px, 100%);

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;

    .image {
      width: 100%;
      height: 350px;
      overflow: hidden;
      position: relative;

      &:hover {
        .secondImg {
          z-index: 2;
        }
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }

      .mainImg {
        z-index: 1;
      }

      .new {
        position: absolute;
        top: 25px;
        left: 5px;
        background-color: white;
        color: teal;
        padding: 3px 5px;
        z-index: 3;
        font-weight: 500;
        font-size: 12px;
        border: 1px solid;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
    }

    .prices {
      display: flex;
      gap: 20px;

      h3 {
        font-size: 18px;
        font-weight: 500;

        &:first-child {
          color: gray;
          text-decoration: line-through;
        }
      }
    }
  }
}
