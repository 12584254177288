// About.module.scss

.privacy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  ol {
    li {
      margin-bottom: 20px;
    }
  }

  h2 {
    margin-top: 30px;
  }
}
