.contact {
  background-color: #7c7c7c;
  color: white;
  padding: 25px 50px;
  display: flex;
  justify-content: center;
  margin: 50px 0;

  .wrapper {
    // max-width: calc((260px + 50px) * 4 - 50px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    @media screen and (max-width: 780px) {
      flex-direction: column;
      height: 150px;
      justify-content: space-between;
      align-items: center;
    }

    .mail {
      display: flex;
      flex-wrap: nowrap;

      input {
        padding: 10px;
        border: none !important;
        outline: none !important;
        //   border-radius: 5px 0 0 5px;
      }

      button {
        padding: 10px;
        color: white;
        background: #333;
        //   border-radius: 0 5px 5px 0;
        border: none;
      }
    }

    .icons {
      display: flex;
      gap: 10px;
    }
  }
}
