.products {
  padding: 30px 50px;
  display: flex;
  gap: 2%;
  flex-wrap: wrap;

  .left {
    flex: 1;
    position: sticky;
    top: 50px;
    height: 100%;
    @media screen and (max-width: 1000px) {
      position: unset;
    }

    .filterItem {
      margin-bottom: 30px;
      cursor: pointer;

      h2 {
        font-weight: 400;
        margin-bottom: 20px;
      }
      .inputItem {
        margin-bottom: 10px;
        display: flex;
        gap: 5px;
        label {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .right {
    flex: 4;
    @media screen and (max-width: 1001px) {
      flex: unset;
      width: 100%;
    }
    .catImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 50px;
    }
  }

  .heading {
    margin-bottom: 20px;
    width: 100%;
    background: rgb(236, 236, 236);
    padding-left: 10px;
    height: 100px;
    display: flex;
    align-items: center;
    font-size: 2rem;
  }
}
