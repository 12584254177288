.slider {
  height: calc(100vh - 80px);
  width: 100vw;
  overflow: hidden;
  .container {
    width: 300vw;
    display: flex;
    height: 100%;
    transition: all 1s ease;

    img {
      width: 100vw;
      height: 100%;
      object-fit: cover;
    }
  }
  .icons {
    display: flex;
    width: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    bottom: 100px;
    gap: 4px;
    .icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: white;
    }
  }
}
