* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: "Space Grotesk", sans-serif;
  line-height: 1.7;
}

body,
html {
  overflow-x: hidden;
}

.link {
  color: inherit;
  text-decoration: none;
}
