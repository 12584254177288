.footer {
  // max-width: calc((260px + 50px) * 4 - 50px);
  margin: 100px 50px 10px;
  padding: 0 0px;
  @media screen and (max-width: 780px) {
    margin: 100px 15px;
  }

  .top {
    display: flex;
    gap: 50px;
    @media screen and (max-width: 780px) {
      flex-direction: column;
    }

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: justify;
      font-size: 14px;

      h1 {
        font-size: 18px;
        font-weight: 500;
        color: #555;
      }

      span {
        color: gray;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    flex-wrap: wrap;

    .left {
      display: flex;
      align-items: center;
      @media screen and (max-width: 780px) {
        display: block;
      }

      .logo {
        // color: #2879fe;
        // font-weight: bold;
        font-size: 24px;
        @media screen and (max-width: 780px) {
          width: 100%;
          display: block;
        }
      }

      .copyright {
        margin-left: 20px;
        font-size: 12px;
        color: gray;
        @media screen and (max-width: 780px) {
          width: 100%;
          display: block;
          margin-left: 0;
        }
      }
    }
    .right {
      img {
        height: 50px;
        @media screen and (max-width: 780px) {
          margin-top: 50px;
          height: auto;
          width: 100%;
          margin-left: -10px;
        }
      }
    }
  }
}
