.categories {
  display: flex;
  height: 80vh;
  margin-top: 100px;
  gap: 20px;
  padding: 0 20px;
  @media screen and (max-width: 780px) {
    flex-direction: column;
    height: auto;
  }
  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .colLarge {
    flex: 2;
    .row {
      gap: 20px;
    }
  }
  .row {
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    // gap: 20px;
    button {
      position: absolute;
      min-width: 100px;
      padding: 10px;
      inset: 0;
      width: fit-content;
      margin: auto;
      cursor: pointer;
      border: none;
      height: 50px;
      text-transform: uppercase;
      text-decoration: none;
      // background: black;
      // color: white;
      font-size: 1.3rem;
      transition: all 0.3s;
      color: black;
      background: white;
      &:hover {
        color: white;
        background: black;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
h2.heading {
  text-align: center;
  margin-top: 100px;
  font-size: 2.5rem;
  line-height: 1.6;
}
