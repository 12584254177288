// Define base styles for .navbar and .wrapper
.navbar {
  .link {
    color: inherit;
    text-decoration: none;
  }

  .wrapper {
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      display: flex;
      align-items: center;
      font-size: 18px;
    }
    .left {
      display: flex;
      align-items: center;
      gap: 25px;
      flex: 1;
    }
    .center {
      font-size: 30px;
      letter-spacing: 2px;
      flex: 1;
      text-align: center;
    }
    .right {
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;
      gap: 25px;
      flex: 1;

      .icons {
        display: flex;
        gap: 15px;
        color: #777;
        cursor: pointer;
      }
    }
  }
  .cartIcon {
    position: relative;
    span {
      font-size: 12px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #2879fe;
      color: white;
      position: absolute;
      right: -10px;
      top: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.hamburger {
  display: none;
}

// media queries
@media screen and (max-width: 780px) {
  .navbar {
    /* hamburger */
    .hamburger {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      cursor: pointer;
      position: relative;
      z-index: 5;
      align-items: center;
    }

    .bar {
      background-color: #120f0b;
      width: 20px;
      height: 3px;
      display: block;
      margin: 5px;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }

    /* adding active class to the bars  */
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }
    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }

    /* navMenu */
    .wrapper {
      flex-direction: column;
      position: absolute;
      inset: 0;
      z-index: 2;
      background: whitesmoke;
      justify-content: center;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in;
      &.active {
        transform: translateX(0%);
      }

      .left {
        align-items: center;
        flex-direction: column;
        order: 2;
        flex: unset;
      }
      .center {
        flex-direction: column;
        order: 1;
        display: none;
      }
      .right {
        flex-direction: column;
        order: 3;
        flex: unset;
        margin-top: 20px;
      }
    }
  }
}
