// Loader.module.scss

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: fixed;
  inset: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.404);
}

.spinner {
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #3b50c7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
